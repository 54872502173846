<template>
  <div><b>{{title}}</b> - {{message }}. <a v-if="link" :href="link">View</a></div>
</template>
<script>
  export default {
    props: ['title','message','link']
  }
</script>
<style>
</style>
