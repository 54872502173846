import api from './api'
import { User } from './user'
import store from '../store'
const API = '/api/login'
const API_REGISTER = '/api/register'

export const Authenticate = {
  register: function(params) {
    return api.create(API_REGISTER, params)
  },
  login: function(params, cb) {
    api.create(API, params).then(result => {
      var token = result.data.access_token
      store.commit('saveToken', token)
      
      this.getUser(function(response){
        cb(response.user, "")
      })
    })
    .catch(error => {
      var message = 'An error occured while authenticating'
      if (error.response) {
        this.$errors = error.response.data
        if (error.response.data) {
          if (error.response.data.error) message = error.response.data.error
        }
      }
      cb(false, message)
    })
  },
  getUser: function(cb) {
    User.currentUser().then(result => {
      if (result.data.user) store.commit('saveUser',result.data.user);
      cb(result.data)
    })
    .catch(error => {
      console.log(error)
      cb(error)
    })
  }
}
