<template>
  <el-tooltip class="item" effect="dark" content="BACK" placement="top-start">

    <div> <el-button type="info" icon="fa fa-arrow-left" circle class="mb-2 mr-2" @click="back"></el-button> <span>{{ label  }}</span></div>
  </el-tooltip>
</template>

<script>
export default {
  props: ['label'],
  data() {
    return {
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    }
  }
}
</script>

<style>
</style>
