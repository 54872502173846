import Vue from 'vue'
import Vuex from 'vuex'

import { LocalStorageService } from '@/services/LocalStorageService'
import {
  UserStatus
} from '@/Constants'

Vue.use(Vuex)
let now = new Date()
let year = now.getFullYear()

export default new Vuex.Store({
  state: {
    isInArray: function (arrayOfObjects, objectToFind) {
      return arrayOfObjects.some(obj => obj.id === objectToFind.id);
    },
    removeDuplicates: function (array, property) {
        let unique = {};
        return array.filter(obj => !unique[obj[property]] && (unique[obj[property]] = true));
    },
    years: [year+1, year, year-1, year-2, year-3,year-4,year-5,year-6],
    count: 0,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + LocalStorageService.getToken(),
    },
    user: LocalStorageService.getUser(),
    userStatus: UserStatus,
    formDataHeaders: {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + LocalStorageService.getToken(),
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Origin": "*"
    },
    tickets_status: ['REJECTED', 'PENDING', 'COMPLETED'],
    ticket_types: [{id: 1,label: 'One-Way'},{id: 2, label: 'Round-Trip'}],
    bookings_status: ['CANCELLED','OPEN','DEPARTED','ON-HOLD'],
    days: ['All Days', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    gender_list: ['MALE', 'FEMALE'],
    users_status: ['PENDING','ACTIVE','INACTIVE'],
    passenger_types: ['REGULAR','STUDENT','SENIOR','CHILD','PWD'],
    invoice_status: ['UNPAID', 'PAID', 'PAST DUE'],
    invoice_status_color: ['warning', 'info', 'success'],
    refund_types: ['(Before) ', '(After) ', '(Free) '],
    resched_types: ['(Before) ', '(After) ', '(Free)'],
    users: []
  },
  mutations: {
    saveToken: (state, token) => {
      LocalStorageService.setToken(token)
      state.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer '+token
      };
    },
    saveUser: (state, user) => {
      LocalStorageService.setUser(user)
      state.user = user;
    },
    setUsers: (state, users) => {
      state.users = users;
    },
  }
})