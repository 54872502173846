import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Overview.vue')
const Widgets = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Widgets.vue')

// Pages
import User from 'src/components/Dashboard/Views/Pages/UserProfile.vue'
import TimeLine from 'src/components/Dashboard/Views/Pages/TimeLinePage.vue'
import Login from 'src/components/Dashboard/Views/Pages/Login.vue'
import Logout from 'src/components/Dashboard/Views/Pages/Logout.vue'

const Signup = () => import(/* webpackChunkName: "Sigunup" */ 'src/components/System/Signup/Signup.vue');

const ForgotPassword = () => import(/* webpackChunkName: "ForgotPassword" */ 'src/components/System/ForgotPassword/ForgotPassword.vue');

const ResetPassword = () => import(/* webpackChunkName: "ResetPassword" */ 'src/components/System/ResetPassword/ResetPassword.vue');

import Lock from 'src/components/Dashboard/Views/Pages/Lock.vue'

// Components pages
import Buttons from 'src/components/Dashboard/Views/Components/Buttons.vue'
import GridSystem from 'src/components/Dashboard/Views/Components/GridSystem.vue'
import Panels from 'src/components/Dashboard/Views/Components/Panels.vue'
import SweetAlert from 'src/components/Dashboard/Views/Components/SweetAlert.vue'
import Notifications from 'src/components/Dashboard/Views/Components/Notifications.vue'
import Icons from 'src/components/Dashboard/Views/Components/Icons.vue'
import Typography from 'src/components/Dashboard/Views/Components/Typography.vue'

// Forms pages
const RegularForms  = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/RegularForms.vue')
const ExtendedForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ExtendedForms.vue');
const ValidationForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ValidationForms.vue')
const Wizard = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/Wizard.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/RegularTables.vue');
const ExtendedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/ExtendedTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/PaginatedTables.vue');
// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/GoogleMaps.vue')
const FullScreenMap = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/FullScreenMap.vue')
const VectorMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/VectorMapsPage.vue');

//Settings pages
const Users = () => import(/* webpackChunkName: "settings" */ 'src/components/System/Settings/Users/UsersList.vue');
const UserForm = () => import(/* webpackChunkName: "settings" */ 'src/components/System/Settings/Users/UserForm.vue');

const Roles = () => import(/* webpackChunkName: "settings" */ 'src/components/System/Settings/Roles/RolesList.vue');


const UserProfile = () => import(/* webpackChunkName: "UserProfile" */ 'src/components/System/Profile/UserProfile.vue');

const MessagesList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Settings/Messages/MessagesList.vue');


//ClientsList
const ClientsList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Clients/ClientsList.vue');

const BranchesList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Branches/BranchesList.vue');
const PortsList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Ports/PortsList.vue');
const RoutesList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Routes/RoutesList.vue');
const AccommodationsList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Accommodations/AccommodationsList.vue');
const VesselsList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Vessels/VesselsList.vue');

const PricesList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Prices/PricesList.vue');
const TripSchedulesList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/TripSchedules/TripSchedulesList.vue');

const BookingsPage = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Bookings/BookingsPage.vue');
const BookingsList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Bookings/BookingsList.vue');
const CheckinPassenger = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Bookings/Checkin/CheckinPassenger.vue');

const TicketingHome = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Ticketing/TicketingHome.vue');

const ClientUsersList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/ClientSettings/ClientUsers/ClientUsersList.vue');

const OnlineSalesReport = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Reports/OnlineSalesReport.vue');

const SalesReport = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Reports/SalesReport.vue');
const InvoicesList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Invoices/InvoicesList.vue');
const RefundedTickets = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Reports/RefundedTicketsList.vue');
const RescheduledTickets = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Reports/RescheduledTicketsList.vue');
const UpgradedTickets = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Reports/UpgradedTicketsList.vue');

const SoldTickets = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Reports/SoldTicketsList.vue');


const PromosList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Promos/PromosList.vue');

const NoTripDatesList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/NoTripDates/NoTripDatesList.vue');


const PassengersList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Passengers/PassengersList.vue');

const RejectedTicketsList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/RejectedTickets/RejectedTicketsList.vue');

const ClientTicketsList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Tickets/ClientTicketsList.vue');
const SearchTickets = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Tickets/SearchTickets.vue');

const ClientInvoicesList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Client/Invoices/ClientInvoicesList.vue');

const UserTransactionRecordsList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Reports/UserTransactionRecordsList.vue');

const SelectedBookingView = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Bookings/SelectedBookingView.vue');

const OnlineBookingsList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/OnlineBookings/OnlineBookingsList.vue');


// Calendar
import Calendar from 'src/components/Dashboard/Views/Calendar/CalendarRoute.vue'
// Charts
const Charts = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Charts.vue')

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'panels',
      name: 'Panels',
      component: Panels
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      component: SweetAlert
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }

  ]
}
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      component: RegularForms
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      component: ExtendedForms
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      component: ValidationForms
    },
    {
      path: 'wizard',
      name: 'Wizard',
      component: Wizard
    }
  ]
}

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      component: RegularTables
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      component: ExtendedTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }]
}


let SettingsMenu = {
  path: '/settings',
  component: DashboardLayout,
  redirect: '/settings',
  children: [
  {
      path: 'messages',
      name: 'Messages',
      component: MessagesList
    },
    {
      path: 'users',
      name: 'Users',
      component: Users
    },
    {
      path: 'users/:id/edit',
      name: 'UserEdit',
      component: UserForm
    },
    {
      path: 'roles',
      name: 'Roles',
      component: Roles
    },
 ]
}

let UserProfileMenu = {
  path: '/my-profile',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'UserProfile',
      component: UserProfile
    },
 ]
}


let ClientsMenu = {
  path: '/clients',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'ClientsList',
      component: ClientsList
    },
 ]
}

let InvoicesMenu = {
  path: '/invoices',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'InvoicesList',
      component: InvoicesList
    },
 ]
}


let ClientMenu = {
  path: '/client',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: 'current-booking',
      name: 'SelectedBookingView',
      component: SelectedBookingView
    },
    {
      path: 'search-tickets',
      name: 'SearchTickets',
      component: SearchTickets
    },
    {
      path: 'all-tickets',
      name: 'ClientTicketsList',
      component: ClientTicketsList
    },
    {
      path: 'passengers',
      name: 'PassengersList',
      component: PassengersList
    },
    {
      path: 'rejected-tickets',
      name: 'RejectedTicketsList',
      component: RejectedTicketsList
    },
    {
      path: 'branches',
      name: 'BranchesList',
      component: BranchesList
    },
    {
      path: 'online-bookings',
      name: 'OnlineBookingsList',
      component: OnlineBookingsList
    },
    {
      path: 'invoices',
      name: 'ClientInvoicesList',
      component: ClientInvoicesList
    },
    {
      path: 'ports',
      name: 'PortsList',
      component: PortsList
    },
    {
      path: 'routes',
      name: 'RoutesList',
      component: RoutesList
    },
    {
      path: 'accommodations',
      name: 'AccommodationsList',
      component: AccommodationsList
    },
    {
      path: 'vessels',
      name: 'VesselsList',
      component: VesselsList
    },
    {
      path: 'prices',
      name: 'PricesList',
      component: PricesList
    },
    {
      path: 'no-trip-dates',
      name: 'NoTripDatesList',
      component: NoTripDatesList
    },
    {
      path: 'promos',
      name: 'PromosList',
      component: PromosList
    },
    {
      path: 'trip-schedules',
      name: 'TripSchedulesList',
      component: TripSchedulesList
    },
    {
      path: 'bookings',
      name: 'BookingsPage',
      redirect: '',
      component: BookingsPage,
      children: [
        {
          path: '',
          name: 'BookingsList',
          component: BookingsList
        },
        {
          path: ':id/checkin',
          name: 'CheckinPassenger',
          component: CheckinPassenger
        },
      ]
    },
    {
      path: 'ticketing',
      name: 'TicketingHome',
      component: TicketingHome
    },
    {
      path: 'reports/user-transactions',
      name: 'UserTransactionRecordsList',
      component: UserTransactionRecordsList
    },
    {
      path: 'reports/online-sales-report',
      name: 'OnlineSalesReport',
      component: OnlineSalesReport
    },
    {
      path: 'reports/sales-report',
      name: 'SalesReport',
      component: SalesReport
    },
    {
      path: 'reports/refunded-tickets',
      name: 'RefundedTickets',
      component: RefundedTickets
    },
    {
      path: 'reports/rescheduled-tickets',
      name: 'RescheduledTickets',
      component: RescheduledTickets
    },
    {
      path: 'reports/upgraded-tickets',
      name: 'UpgradedTickets',
      component: UpgradedTickets
    },
    {
      path: 'reports/sold-tickets',
      name: 'SoldTickets',
      component: SoldTickets
    },
    {
      path: 'users',
      name: 'ClientUsersList',
      component: ClientUsersList
    },
 ]
}

let AccountMenu = {
  path: '/account',
  component: DashboardLayout,
  redirect: '/account/info',
  children: [
    {
      path: 'info',
      name: 'UserInfo',
      component: User
    },
  ]
}

let logoutMenu = {
  path: '/logout',
  component: DashboardLayout,
  redirect: '/logout/user',
  children: [
    {
      path: 'user',
      name: 'Logout',
      component: Logout
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Signup
}

let ForgotPasswordPage = {
  path: '/forgot-password',
  name: 'ForgotPassword',
  component: ForgotPassword
}

let resetPasswordPage = {
  path: '/reset-password',
  name: 'ResetPassword',
  component: ResetPassword
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DashboardLayout,
    redirect: '/overview',
    children: [
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      }
    ]
  },
  AccountMenu,
  UserProfileMenu,
  ClientsMenu,
  InvoicesMenu,
  ClientMenu,
  SettingsMenu,
  loginPage,
  logoutMenu,
  registerPage,
  ForgotPasswordPage,
  resetPasswordPage,
  lockPage,
  {
    path: '',
    component: DashboardLayout,
    redirect: '/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      }
    ]
  },
  {path: '*', component: NotFound}
];

export default routes
