<template>
  <div class="sidebar" :data-color="backgroundColor" :data-active-color="activeColor">
    <div class="logo" style="background: #fff;padding:0">
      <a class="simple-text logo-mini" aria-label="sidebar mini logo" href="/">
        <!-- <div class="logo-img small-logo">
              <img src="@/assets/images/app_logo.png" alt="">
          </div> -->
      </a>
      <a class="simple-text logo-normal" href="/"
        style="background: #080707e8;padding:0;height: 65px;text-align: center;">
        <img src="@/assets/images/app_logo.png" style="height:65px;padding:0" />

        <!-- {{ title }} -->
      </a>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot>

      </slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sideLinks" :key="link.name + index" :link="link">

            <sidebar-item v-for="(subLink, index) in link.children" :key="subLink.name + index" :link="subLink">
            </sidebar-item>
          </sidebar-item>
        </slot>

      </ul>
    </div>
  </div>
</template>
<script>
import 'perfect-scrollbar/css/perfect-scrollbar.css'
  import helpers from '@/Mixins/Helper'
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['user'])
  },
    props: {
        title: {
          type: String,
          default: 'BIZ-DEPOT',
          description: 'Sidebar title'
        },
        backgroundColor: {
            type: String,
            default: 'black',
            validator: (value) => {
              let acceptedValues = ['white', 'brown', 'black']
              return acceptedValues.indexOf(value) !== -1
            },
            description: 'Sidebar background color (white|brown|black)'
          },
          activeColor: {
            type: String,
            default: 'success',
            validator: (value) => {
              let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
              return acceptedValues.indexOf(value) !== -1
            },
            description: 'Sidebar active text color (primary|info|success|warning|danger)'
          },
          logo: {
            type: String,
            default: 'static/img/bd_logo.png',
            description: 'Sidebar Logo'
          },
          sidebarLinks: {
            type: Array,
            default: () => [],
            description: 'Sidebar links. Can be also provided as children components (sidebar-item)'
          },
          autoClose: {
            type: Boolean,
            default: true
          }
      },
    data() {
      return {
        sideLinks: []
      }
  },
  watch: {
    user: function () {
        this.verifySideBarLinks()
      }
    },
    provide() {
      return {
        autoClose: this.autoClose
      }
    },
    methods: {
      async initScrollBarAsync () {
        let isWindows = navigator.platform.startsWith('Win');
        if(!isWindows) {
          return;
        }
        const PerfectScroll = await import('perfect-scrollbar')
        PerfectScroll.initialize(this.$refs.sidebarScrollArea)
      },
      verifySideBarLinks() {
        let links = []

        this.sidebarLinks.forEach(l => {
          let link = Object.assign({}, l)
          if (link.children) {
            let childrenLinks = []
            link.children.forEach(function (clink) {
              if (helpers.isAllowed(clink.module, clink.action)) childrenLinks.push(clink)
            })
            link.children = childrenLinks
          }

          if (link.module && helpers.isAllowed(link.module, link.action)) {
            links.push(link)
          }
        });

        this.sideLinks = links
      }
    },
    created: function () {
      this.verifySideBarLinks()
    },
    mounted () {
      this.initScrollBarAsync()
    },
    beforeDestroy () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false
      }
    }
  }

</script>
<style>
  @media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu{
      display: none;
    }
  }
</style>
