<template>
  <el-page-header @back="goBack" :content="title"></el-page-header>
</template>
<script>

export default {
  props: {
    url: {
      type: String,
      description: 'Optional url to go back',
      default: null
    },
    title: {
      type: String,
      description: 'Heading Title',
      default: null
    },
  },
  data() {
    return {
      links: [],
      state: '',
      timeout: null
    };
  },
  methods: {
    goBack() {
      if (this.url) this.$router.push(this.url)
      else this.$router.go(-1)
    },
  }
};
</script>

<style scoped>
.error-text {
  display: block;
}
</style>