<template>
  
  <div class=""
       :class="[
       {'input-group': hasIcon},
       {'has-danger': error},
       {'input-group-focus': focused},
       {'has-label': label || $slots.label},
       {'has-success': hasSuccess}]">
      <slot name="label">
          <label v-if="label" :class="labelClasses">
            {{ label }}
            <span class="text-danger" v-if="required">*</span>
          </label>
      </slot>


       <div :class="addonLeftIcon || addonRightIcon ? 'input-group' : ''">
          <div class="input-group-prepend" v-if="addonLeftIcon">
            <span class="input-group-text" id="basic-addon1" style="padding-right: 10px">
              <i :class="addonLeftIcon" style=""></i>
            </span>
          </div>

            <input
       
              v-if="!textarea"
              :maxlength="maxlength"
              :readonly="readonly"
              :value="value"
              v-on="listeners"
              v-bind="$attrs"
              :type="type"
              :min="minDate"
              v-mask="mask"
              :step="step"
              class="form-control"
              :required="required"
              :class="[{ 'is-valid': hasSuccess }, inputClasses, { 'is-invalid': error }]"
              aria-describedby="addon-right addon-left">     
              
               <textarea
            rows="5"
            v-if="textarea"
            :value="value"
            v-on="listeners"
            v-bind="$attrs"
            class="form-control"
            :required="required"
            :class="[{ 'is-valid': hasSuccess }, inputClasses, { 'is-invalid': error }]"
            aria-describedby="addon-right addon-left"></textarea>
          
          <div class="input-group-append" v-if="addonRightIcon">
              <span class="input-group-text" id="basic-addon1">
                <i :class="addonRightIcon"></i>
              </span>
            </div>
        </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div class="invalid-feedback error-text" :class="{'mb-3': hasIcon}" v-if="error">
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>

  export default {
  inheritAttrs: false,
    name: 'fg-input',
  props: {
      readonly: {
        type: Boolean,
        description: 'Whether input is readonly',
        default: false
      },
      textarea: {
        type: Boolean,
        description: 'Whether input is textarea',
        default: false
      },
      required: {
        type: Boolean,
        description: 'Whether input is required (adds an asterix *)'
      },
      label: {
        type: String,
        description: 'Input label (text before input)'
    },
      type: {
        type: String,
        default: 'text',
        description: 'Input type'
    },
      error: {
        type: String,
        description: 'Input error (below input)'
      },
      labelClasses: {
        type: String,
        description: 'Input label css classes'
      },
      inputClasses: {
        type: String,
        description: 'Input css classes'
      },
      value: {
        type: [String, Number],
        description: 'Input value'
      },
      addonRightIcon: {
        type: String,
        description: 'Addon right icon'
      },
      addonLeftIcon: {
        type: String,
        description: 'Addont left icon'
    },
      minDate: {
        default: null
    },
      step: {
      default: 'any'
    },
      maxlength: {
        default: 999
    },
      mask: {
        default: null
      }
    },
    data() {
      return {
        touched: false,
        focused: false,
        hadError: false
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.updateValue,
          focus: this.onFocus,
          blur: this.onBlur
        }
      },
      hasSuccess() {
        return this.hadError && this.touched && !this.error
      },
      hasIcon() {
        const { addonRight, addonLeft } = this.$slots
        return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined
      }
    },
    methods: {
      updateValue(evt) {
        let value = evt.target.value
        if (!this.touched && value) {
          this.touched = true
        }
        this.$emit('input', value)
      },
      onFocus(value) {
        this.focused = true;
        this.$emit('focus', value);
      },
      onBlur(value) {
        this.focused = false;
        this.$emit('blur', value);
      }
    },
    created() {
      this.$watch('error', (newVal) => {
        if(newVal){
          this.hadError = true;
        }
      }, {immediate: true})
    }
  }
</script>
<style scoped>
.error-text {
  display: block;
}
</style>
