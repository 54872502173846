<template>
  <div >
    Logging out ...
  </div>
</template>
<script>

import { LocalStorageService } from '@/services/LocalStorageService'

export default {
  name: 'Logout',
  created: function () {
    var self = this
    setTimeout(function () { self.logout() }, 1000);
  },
  methods: {
    logout() {
      this.$store.commit('saveUser', null);
      LocalStorageService.setToken(null)
      LocalStorageService.setUser(null)
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
<style></style>


