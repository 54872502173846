<template>
    <div class="loading-bg" v-if="loading" style="z-index: 99999;    text-align: center;">
      <!-- <div  style="font-size: 20px;color: #fff;margin-top: 100px;" class="loading-icon"><b-icon icon="circle-fill" animation="throb" font-scale="4"></b-icon></div> -->
      <div style="font-size: 20px;margin-top: 150px;" class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div style="font-size: 20px;margin-top: 150px;" class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div style="font-size: 20px;margin-top: 150px;" class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div style="font-size: 20px;margin-top: 150px;" class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div style="font-size: 20px;margin-top: 150px;" class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
  
    </div>
  </template>
  
  <script>
    export default {
      props: ['loading'],
      data() {
        return {
          busy: false,
          processing: false,
          counter: 1,
          interval: null
        }
      },
      beforeDestroy() {
        this.clearInterval()
      },
      methods: {
        clearInterval() {
          if (this.interval) {
            clearInterval(this.interval)
            this.interval = null
          }
        },
      
        onSubmit() {
          this.processing = false
          this.busy = true
        },
        onCancel() {
          this.busy = false
        },
        
      }
    }
  </script>
  
  <style scoped>
    .loading-bg{
      position: fixed;
      left: 0;
      top: 0;
      background: #1d1c1c;
      width: 100%;
      height: 100%;
      z-index: 10;
      opacity: .5;
    }
  </style>