export const LocalStorageService = {
    getLoadingUser: function() {
      let status = localStorage.getItem('loading_user')
      if (status == 'true') status = true
      if (status == 'false') status = false
      return status != null  ? status : true;
    },
    setLoadingUser: function(status) {
      localStorage.setItem('loading_user', status);
  },
    getCheckinTickets: function() {
      return JSON.parse(localStorage.getItem('checkin_tickets'));
    },
    setCheckinTickets: function(tickets) {
      localStorage.setItem('checkin_tickets', JSON.stringify(tickets));
    },
    getToken: function() {
      return localStorage.getItem('token');
    },
    setToken: function(token) {
      localStorage.setItem('token', token);
    },
    getUser: function() {
      var user = localStorage.getItem('user')
      if (user) user = JSON.parse(user);
      return user;
    },
    setUser: function(user) {
      localStorage.setItem('user', user ? JSON.stringify(user) : null);
  },
  getStates: function () {
      return JSON.parse(localStorage.getItem('states'));
    },
    setStates: function(states) {
      localStorage.setItem('states', JSON.stringify(states));
    },
  }
  