import api from './api'

const API = '/api/users'

export const User = {
  setClient: function(params) {
    return api.create(API+'/set-client', params)
  },
  currentUser: function() {
    return api.get(API+'/me')
  },
  getCompanies: function(params) {
    return api.get(API+'/companies', params)
  },
  setCompany: function (form) {
    return api.create(API+'/set-company', form)
  },
  updateCompanies: function (form) {
    return api.update(API+'/'+form.id+'/update-companies', form)
  },
  updateRoles: function (form) {
    return api.update(API+'/'+form.id+'/update-roles', form)
  },
  verifyPaymentMethodToken: function(params) {
    return api.get(API+'/verify-payment-method-token?token='+params.token)
  },
  updateCredential: function(params) {
    return api.create(API+'/update-credential', params)
  },
  updateFcm: function(params) {
    return api.create(API+'/update-fcm', params)
  },
  approvePayment: function(params) {
    return api.create(API+'/approve-payment', params)
  },
  getUserCashRequest: function(params) {
    return api.get(API+'/user-cash-request', params)
  },
  createPaymentLink: function(params) {
    return api.create(API+'/create-payment-link', params)
  },
  settings: function() {
    return api.get(API+'/settings')
  },
  updatePassword: function(params) {
    return api.create(API+'/update_password', params)
  },
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  updateCompany: function(params) {
    return api.update(API+'/'+params.id+'/update-company', params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  },
  setupPlan: function(params) {
    return api.create(API+'/setup-plan', params)
  },
}
