import api from './api'

const API = '/api/client/users'

export const ClientUser = {
  getRoutes: function(params) {
    return api.get(`${API}/available-routes`, {params: params}) 
  },
  getTrips: function(params) {
    return api.get(`${API}/available-trips`, {params: params}) 
  },
  getBooking: function(params) {
    return api.get(`${API}/available-booking`, {params: params}) 
  },
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}