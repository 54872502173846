<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <div class="row">
            <div class="col-md-3" v-for="company in companies" :key="company.id">
              <el-card style="padding: 10px;">
                {{ company.name }} <el-button style="float: right;" size="small" type="primary" @click="handleSelect(company)">SELECT</el-button>
              </el-card>
            </div>
          </div>
        </div>
        <div class="col-sm-6 pagination-info mt-5">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6 mt-5">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { User } from '@/resources/user'

export default {
  data() {
    return {
      showDelete: false,
      showForm: false,
      companies: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getCompanies()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getCompanies()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getCompanies()
    }
  },
  created: function () {
    this.getCompanies()
  },
  methods: {
    handleSelect(company) {
      this.$loading = true
      User.setCompany({company_id: company.id})
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Company has been set',
          });

          window.location.reload();
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to set company',
          });

        }).finally(() => {
          this.$loading = false
        })
    },
    getCompanies() {
      let params = { term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage }
      this.$loading = true
      User.getCompanies({ params: params })
        .then(result => {
          if (result.data.companies)
            this.companies = result.data.companies
          this.pagination = result.data.meta
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch companies',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
