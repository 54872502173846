<template>
  <div class="full-page-background" style="background-image: url(static/img/background/background-2.jpg) "></div>
</template>
<script>
export default {
  data() {
    return {
      activeName: 'first'
    }
  }
}
</script>
<style></style>
